import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Box } from '@core';
import { Animation, Icon, Text } from '@components';
import { query } from '@shared/style-helpers';

const PickerBox = styled(Box)`
  display: grid;
  grid-template-rows: repeat(8, auto);
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  grid-column-gap: 40px;

  @media ${query.maxMobile} {
    grid-template-rows: repeat(11, auto);
  }
`;

const BasePicker = ({
  selected,
  options,
  openedWrapperProps,
  wrapperProps,
  dataTestId,
  onOptionClick,
  isMobileFullWidth,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(selected);

  const handleToggle = () => setIsOpen(!isOpen);

  const handleOptionClick = (option) => () => {
    setSelectedOption(option);
    setIsOpen(false);

    if (onOptionClick) {
      onOptionClick(option);
    }
  };

  useEffect(() => {
    const handleClickOutside = () => {
      if (isOpen) {
        setIsOpen(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [isOpen]);

  return options.length > 1 ? (
    <Box
      position={{ _: isMobileFullWidth ? 'static' : 'relative', lg: 'relative' }}
      data-testid={dataTestId && `${dataTestId}-container`}
      {...wrapperProps}
    >
      <Box display="flex" justifyContent="center">
        <Box
          data-testid={dataTestId}
          px={{ _: 16, md: 0 }}
          onClick={handleToggle}
          display="flex"
          alignItems="center"
          columnGap={8}
        >
          <Text.Body3>{selectedOption?.value?.toUpperCase()}</Text.Body3>
          <Animation.Rotate rotate={isOpen ? 90 : 0}>
            <Box display="flex">
              <Icon.Down color="fontDefault" size={16} />
            </Box>
          </Animation.Rotate>
        </Box>
      </Box>
      {isOpen && (
        <PickerBox
          position="absolute"
          right={{ _: 0, lg: -20 }}
          zIndex={10}
          width={{ _: isMobileFullWidth ? '100%' : 'auto', lg: 'auto' }}
          borderRadius="sm"
          backgroundColor="pickerBackground"
          boxShadow="0px 2px 10px rgba(0, 0, 0, 0.1)"
          mt={8}
          py={8}
          pl={16}
          pr={40}
          {...openedWrapperProps}
        >
          {options.map((option, index) => {
            const isSelected = selectedOption?.value === option.value;

            return (
              <Box key={index} display="flex" alignItems="center" my={8}>
                {isSelected && <Icon.Check size={15} color="fontDefault" />}
                <Box
                  data-testid={dataTestId && `${dataTestId}-option-${index}`}
                  ml={isSelected ? 9 : 24}
                  onClick={!isSelected ? handleOptionClick(option) : null}
                  minWidth={32}
                >
                  <Text.Body4 lineHeight="15px">{option.displayValue}</Text.Body4>
                </Box>
              </Box>
            );
          })}
        </PickerBox>
      )}
    </Box>
  ) : null;
};

export default BasePicker;
